import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Statistic } from "antd";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import { api } from "../../../services/api";
import moment from "moment";
import Chart from "react-apexcharts";
import _ from "lodash";
import { DatePickerComponent } from "../../../containers/inputs/date-picker-component/date-picker-component";

export const UserIndexPage = () => {
    const [dreamPanel, setDreamPanel] = useState<any>({
        metricMonth: [],
        metricLucidity: [],
        metricDreamQuality: [],
        metricSleepQuality: [],
    });
    const [date, setDate] = useState({
        dateStart: moment().subtract(1, "month"),
        dateEnd: moment(),
    });
    const navigate = useNavigate();
    const [users, setUsers] = useState<any>();
    const [totalUser, setTotalUser] = useState<any>();
    const [dreams, setDreams] = useState<any>();
    useEffect(() => {
        api.get("/user", {
            params: {
                role: "user",
                dateStart: date.dateStart.format("YYYY-MM-DD"),
                dateEnd: date.dateEnd.format("YYYY-MM-DD"),
            },
        })
            .then((response) => {
                setUsers(response.data);
                setTotalUser(response.data.length);
                console.log(response.data, "DEKKO");
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    }, []);
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Ultimo registro",
            dataIndex: "lastDream",
            key: "lastDream",
            render: (record: any) => (
                <span>{moment(record?.created_at).fromNow()}</span>
            ),
        },
        {
            title: "Sonhos",
            dataIndex: "totalDreams",
            key: "totalDreams",
            render: (record: any) => <span>{record?.length}</span>,
        },
    ];

    return (
        <div>
            <h1>User Index Page</h1>
            <Button onClick={() => navigate("/users/create")}>
                Create user
            </Button>
            <DatePickerComponent
                value={date.dateStart}
                onChange={(val) => setDate({ ...date, dateStart: val })}
            />
            <DatePickerComponent
                value={date.dateEnd}
                onChange={(val) => setDate({ ...date, dateEnd: val })}
            />
            {/*<Button onClick={fetchPanel}>Search</Button>*/}
            <Row>
                <Card>
                    <Statistic title="Total de usuários" value={totalUser} />
                </Card>
            </Row>
            <Row>
                <Col span={12}>
                    <Card>
                        <Chart
                            options={{
                                labels: dreamPanel.metricMonth.map(
                                    (item: any) =>
                                        moment(item.date).format("DD/MM"),
                                ),
                                chart: { type: "line" },
                                yaxis: {
                                    labels: {
                                        formatter: (val: any) => {
                                            return val.toFixed(1);
                                        },
                                    },
                                },
                                title: {
                                    text: `Usuários ${date.dateStart.format(
                                        "DD/MM/YYYY",
                                    )} - ${date.dateEnd.format("DD/MM/YYYY")}`,
                                },
                            }}
                            series={[
                                {
                                    type: "column",
                                    name: "Dreams",
                                    data: dreamPanel.metricMonth.map(
                                        (item: any) => item.count,
                                    ),
                                },
                                {
                                    type: "line",
                                    name: "Dreams Average",
                                    data: dreamPanel.metricMonth.map(
                                        (item: any) =>
                                            _.meanBy(
                                                dreamPanel.metricMonth,
                                                (item: any) => item.count,
                                            ),
                                    ),
                                },
                            ]}
                            type="line"
                            height={350}
                        />
                    </Card>
                </Col>
            </Row>
            <Table dataSource={users} columns={columns} />
        </div>
    );
};
