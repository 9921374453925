import React, { useEffect, useState } from "react";
import { FormTeam } from "../../containers/forms/form-team/form-team";
import { TableUser } from "../../containers/tables/table-user";
import { api } from "../../services/api";

export const AdminIndexPage = () => {
    const [users, setUsers] = useState<any>();

    useEffect(() => {
        api.get("/user", {params: { role: "admin" }}).then((response) => {
            // response.data.map((user) => {
            //     if (user.role !== "admin"){
            //
            //     }
            // })
            setUsers(response.data);
        })
            .catch((error) => {
                console.log(error);
                return [];
            })
    }, [])
    return (
        <div>
            <h1>Admin Index Page</h1>
            <FormTeam role={'admin'}/>
            <TableUser dataSource={users}/>
        </div>
    );
}