import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import _ from "lodash";
import { notification, Space, Typography } from "antd";
import React from "react";

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3333";
const api = axios.create({ baseURL });
api.interceptors.request.use(
    async function (config) {
        // Do something before request is sent
        const user = JSON.parse(localStorage.getItem("user")) as {
            accessToken: string | null;
        };
        if (user && user.accessToken !== null) {
            config.headers["x-access-token"] = user.accessToken;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

api.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (
            response.status === 201 ||
            (response.status === 200 && response.config.method === "post")
        ) {
            notification.success({ message: "Registro criado com sucesso!" });
        }
        if (response.status === 200 && response.config.method === "put") {
            notification.success({
                message: "Registro atualizado com sucesso!",
            });
        }
        return response;
    },
    function (error) {
        const response = error.response;
        if (response.status === 422) {
            const body = [];
            _.forEach(response.data.body, (fieldErrors) => {
                body.push(fieldErrors);
            });
            if (body.length) {
                notification.error({
                    message: response.data.message,
                    description: (
                        <Space direction={"vertical"}>
                            {body.map((error) => (
                                <Typography.Text strong key={error}>* {error}</Typography.Text>
                            ))}
                        </Space>
                    ),
                });
            } else {
                notification.error({
                    message:
                        response?.data?.message ||
                        response?.data?.error?.message ||
                        "Message",
                });
            }
        }

        if (
            response.status === 403 ||
            response.status === 401 ||
            response.status === 404
        ) {
            notification.error({ message: response.data.message });
        }

        if (response.status === 500) {
            notification.error({ message: response.data.message || "Error no servidor" });
        }
        // Do something with request error
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    },
);


export { api };
